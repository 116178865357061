import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./ExtJobSearchPage.scss";
import awsConfig from "../../awsConfig/awsConfig.json";

const ExtSearch = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const scrollToSearch = useRef();
  const [postedJobs, setPostedJobs] = useState([]);
  const [redirectMessage, setRedirectMessage] = useState("");
  const [searching, setSearching] = useState(false);
  const glitterVideo = `${awsConfig.s3BucketUrl}${awsConfig.videoPath}`;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setRedirectMessage("You must sign in before searching.   Redirecting...");
      setTimeout(() => {
        navigate("/signin");
      }, 1200);
    }
  }, [navigate]);

  useEffect(() => {
    if (postedJobs.length > 0 && scrollToSearch.current) {
      scrollToSearch.current.scrollIntoView({ behavior: "smooth" });
    } else if (jobs.length > 0 && scrollToSearch.current) {
      scrollToSearch.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [postedJobs, jobs]);

  const handleSearch = async (event) => {
    event.preventDefault();

    if (query.trim() === "") {
      return;
    }

    setSearching(true);
    setSelectedJob(null);
    setShowResults(false);
    setSearching(true);

    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/extsearch`;
      const token = localStorage.getItem("token");
      const extResponse = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { query },
      });

      setJobs(extResponse.data.data || []);
      const postedResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/postedjobs`
      );
      const postedJobsRaw = postedResponse.data || [];
      const filteredAndSortedPostedJobs = filterAndSortPostedJobs(
        postedJobsRaw,
        query
      );
      setPostedJobs(filteredAndSortedPostedJobs);
      setShowResults(true);
    } catch (error) {
      console.error("Error fetching search data", error);
    } finally {
      setSearching(false);
    }

    setSelectedJob(null);
  };

  const handleJobDetails = (job) => {
    setSelectedJob(job);
  };

  const handleReturnToList = () => {
    setSelectedJob(null);
  };

  const handleLocalJobDetails = (postedJob) => {
    setSelectedJob(postedJob);
  };

  const handleLocalReturnToList = () => {
    setSelectedJob(null);
  };

  const isDateWithinTwoMonths = (timestamp) => {
    const currentDate = new Date();
    const postedDate = new Date(timestamp * 1000);
    const diffInMonths =
      (currentDate.getFullYear() - postedDate.getFullYear()) * 12 +
      (currentDate.getMonth() - postedDate.getMonth());
    return diffInMonths <= 2;
  };

  const postedJobMatchesQuery = (job, searchQuery) => {
    const normalizedQuery = String(searchQuery).toLowerCase();
    const queryWords = normalizedQuery.split(/[\s,]+/).filter(Boolean);

    const jobLocation = job.location?.toLowerCase() ?? "";
    const jobSkillsRequired = job.skillsRequired?.toLowerCase().split(/,\s*/);
    const jobTitle = job.jobTitle?.toLowerCase() ?? "";

    const acceptedJobTitles = [
      "full stack developer",
      "fullstack developer",
      "full stack engineer",
      "fullstack engineer",
      "software engineer",
      "software developer",
      "front end developer",
      "back end developer",
      "front end engineer",
      "back end engineer",
      "frontend developer",
      "backend developer",
      "frontend engineer",
      "backend engineer",
      "javascript engineer",
      "python engineer",
      "javascript developer",
      "python developer",
      ".NET engineer",
      ".NET developer",
      "PHP engineer",
      "PHP enginer",
      "C# engineer",
      "C++ engineer",
      "python engineer",
      "web designer",
      "web developer",
      "ux designer",
      "ui designer",
      "data scientist",
      "data analyst",
      "data engineer",
      "data science expert",
    ];

    const locationMatch = queryWords.some((word) => jobLocation.includes(word));
    if (!locationMatch) return { match: false };

    const matchingTitles = acceptedJobTitles.filter((title) =>
      normalizedQuery.includes(title)
    );

    const titleMatch = matchingTitles.some((title) => jobTitle.includes(title));

    const skillsMatch = jobSkillsRequired.some((skill) =>
      queryWords.includes(skill)
    );

    const match = locationMatch && (titleMatch || skillsMatch);
    const isPriority = locationMatch && titleMatch && skillsMatch;

    return {
      match: match,
      isPriority: isPriority,
    };
  };

  const filterAndSortPostedJobs = (jobs, searchQuery) => {
    const filteredJobs = jobs
      .map((job) => ({
        ...job,
        ...postedJobMatchesQuery(job, searchQuery),
      }))
      .filter((job) => job.match);

    return filteredJobs.sort((a, b) => b.isPriority - a.isPriority);
  };

  if (redirectMessage) {
    return <div className="redirect-message">{redirectMessage}</div>;
  }

  return (
    <section className="jsearch">
      <div className="jsearch__background-video">
        <video
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          style={{ opacity: 0, transition: "opacity 0.5s ease" }}
          onLoadedData={(event) => {
            event.target.style.opacity = 1;
          }}
        >
          <source src={glitterVideo} type="video/mp4" />
        </video>
      </div>
      <h1 className="jsearch__title">JOBS</h1>

      <form className="jsearch__form" onSubmit={handleSearch}>
        <div className="jsearch__input-box">
          <label className="jsearch__input-label" htmlFor="queryInput">
            Enter your criteria
          </label>
          <textarea
            className="jsearch__input"
            id="queryInput"
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            placeholder="Include job title, location, and any other criteria"
          ></textarea>
        </div>
        <div className="jsearch__button-wrapper">
          <button
            className="jsearch__button"
            type="submit"
            disabled={searching}
          >
            {searching ? (
              <>
                <span className="jsearch__searching-text">Searching...</span>
              </>
            ) : (
              "SEARCH"
            )}
          </button>
        </div>
      </form>
      <div className="jsearch__filler"></div>
      <div className="jsearch__results-box">
        {showResults && (
          <div ref={scrollToSearch} className="jsearch__local-results-list">
            <div>
              <h2 className="jsearch__local-results-title">
                JOBS POSTED ON CODERATI
              </h2>
            </div>

            {postedJobs.length > 0 ? (
              postedJobs.map((postedJob) => (
                <div className="jsearch__result-box-wrapper" key={postedJob.id}>
                  <div className="jsearch__result-box">
                    <div className="jsearch__title-box">
                      <div className="jsearch__co-placeholder"></div>
                      <h2 className="jsearch__job-title">
                        {postedJob.jobTitle}
                      </h2>
                    </div>
                    <div>
                      <p className="jsearch__detail">{postedJob.company}</p>
                      <p className="jsearch__detail">{postedJob.location}</p>
                    </div>
                    {selectedJob && selectedJob.id === postedJob.id ? (
                      <>
                        {Object.entries(selectedJob).map(
                          ([key, value]) =>
                            (key === "jobDescription" ||
                              key === "experience" ||
                              key === "datePosted" ||
                              key === "applyBy" ||
                              key === "postedBy" ||
                              key === "email" ||
                              ((key === "minSalary" || key === "maxSalary") &&
                                value !== null)) && (
                              <p className="jsearch__detail-extended" key={key}>
                                {key === "minSalary"
                                  ? `MIN SALARY: ${value}`
                                  : key === "maxSalary"
                                    ? `MAX SALARY: ${value}`
                                    : `${key
                                        .replace(/([A-Z])/g, " $1")
                                        .trim()
                                        .toUpperCase()}: ${value}`}
                              </p>
                            )
                        )}
                        <button
                          className="jsearch__button-back"
                          onClick={handleLocalReturnToList}
                        >
                          BACK TO LIST
                        </button>
                      </>
                    ) : (
                      <button
                        className="jsearch__details-button"
                        onClick={() => handleLocalJobDetails(postedJob)}
                      >
                        CHECK OUT DETAILS
                      </button>
                    )}
                    {typeof postedJob.apply === "string" && (
                      <a
                        className="jsearch__apply-link"
                        href={`mailto:${postedJob.apply}?subject=Application for the role: ${postedJob.jobTitle}&body=Dear ${postedJob.postedBy},%0D%0A%0D%0AI am writing to express my interest in the position of ${postedJob.jobTitle} at your company ${postedJob.company}.%0D%0A%0D%0A[Add personal message here]%0D%0A%0D%0AYours,%0D%0A%0D%0A[Add your name]%0D%0A%0D%0A`}
                      >
                        APPLY
                      </a>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <h3 className="jsearch__no-jobs-line">
                No matching jobs currently posted directly on Coderati
              </h3>
            )}
          </div>
        )}
        {showResults && (
          <div className="jsearch__results-list">
            <div>
              <h2 className="jsearch__local-results-title">
                JOBS POSTED ELSEWHERE
              </h2>
            </div>
            {jobs.length > 0 ? (
              jobs
                .filter((job) =>
                  isDateWithinTwoMonths(job.job_posted_at_timestamp)
                )
                .map((job) => (
                  <div className="jsearch__result-box-wrapper" key={job.job_id}>
                    <div className="jsearch__result-box">
                      <div className="jsearch__title-box">
                        {job.employer_logo ? (
                          <div className="jsearch__co-logo-container">
                            <img
                              className="jsearch__co-logo"
                              src={job.employer_logo}
                              alt="Co logo"
                            />
                          </div>
                        ) : (
                          <div className="jsearch__co-placeholder"></div>
                        )}
                        <h2 className="jsearch__job-title">{job.job_title}</h2>
                      </div>
                      <div>
                        <p className="jsearch__detail">{job.employer_name}</p>
                        <p className="jsearch__detail">{job.job_city}</p>
                        <p className="jsearch__detail">
                          {job.job_required_skills}
                        </p>
                      </div>
                      {selectedJob && selectedJob.job_id === job.job_id ? (
                        <>
                          {Object.entries(selectedJob).map(([key, value]) =>
                            key === "job_description" ||
                            key === "job_employment_type" ||
                            key === "job_posted_at_timestamp" ||
                            key === "job_offer_expiration_timestamp" ||
                            (key.includes("salary") && value !== null) ? (
                              <p className="jsearch__detail-extended" key={key}>
                                {key === "job_posted_at_timestamp"
                                  ? `DATE POSTED: ${new Date(
                                      value * 1000
                                    ).toLocaleDateString("en-GB")}`
                                  : key === "job_offer_expiration_timestamp"
                                    ? `APPLY BY: ${new Date(
                                        value * 1000
                                      ).toLocaleDateString("en-GB")}`
                                    : key.includes("salary")
                                      ? key === "job_min_salary"
                                        ? `MIN SALARY: £${value.toLocaleString()}`
                                        : key === "job_max_salary"
                                          ? `MAX SALARY: £${value.toLocaleString()}`
                                          : null
                                      : `${key
                                          .replace(/_/g, " ")
                                          .toUpperCase()}: ${value}`}
                              </p>
                            ) : null
                          )}
                          <button
                            className="jsearch__button-back"
                            onClick={handleReturnToList}
                          >
                            BACK TO LIST
                          </button>
                        </>
                      ) : (
                        <button
                          className="jsearch__details-button"
                          onClick={() => handleJobDetails(job)}
                        >
                          CHECK OUT DETAILS
                        </button>
                      )}
                      {typeof job.job_apply_link === "string" && (
                        <a
                          className="jsearch__apply-link"
                          href={job.job_apply_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          APPLY
                        </a>
                      )}
                    </div>
                  </div>
                ))
            ) : (
              <h3 className="jsearch__no-ext-jobs-line">
                No matching jobs found
              </h3>
            )}
          </div>
        )}
      </div>
    </section>
  );
};
export default ExtSearch;
