import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Recruiter.scss";
import awsConfig from "../../awsConfig/awsConfig.json";

function RecruiterSignUp() {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    company: "",
    sector: "",
    address: "",
    locationCity: "",
    phone: "",
    email: "",
    setPassword: "",
    checkPassword: "",
  });
  const glitterVideo = `${awsConfig.s3BucketUrl}${awsConfig.videoPath}`;

  const [passwordError, setPasswordError] = useState(false);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });

    if (
      event.target.name === "setPassword" ||
      event.target.name === "checkPassword"
    ) {
      setPasswordError(false);
    }

    if (
      event.target.name === "checkPassword" &&
      formData.setPassword !== event.target.value
    ) {
      setPasswordError(true);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.setPassword !== formData.checkPassword) {
      setPasswordError(true);
      return;
    }

    const submissionData = {
      ...formData,
      password: formData.setPassword,
    };

    delete submissionData.setPassword;
    delete submissionData.checkPassword;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/recruiterSignUP`,
        submissionData
      );

      if (response.status === 200 || response.status === 201) {
        setSuccessMessage("Sign up: successful!");
        setTimeout(() => {
          navigate("/signin");
        }, 1500);
      } else {
        console.error(
          "Sign-up was not successful. Status code:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error submitting signup form", error);
      setErrorMessage("An error occurred during sign up. Please try again.");
    }
  };

  return (
    <section className="recruiter-signup">
      <div className="recruiter-signup__titlebox">
        <div className="recruiter-signup__background-video">
          <video
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            style={{ backgroundColor: "$opaque-background-color" }}
            onLoadedData={(event) =>
              (event.target.style.backgroundColor = "transparent")
            }
          >
            <source src={glitterVideo} type="video/mp4" />
          </video>

          <h1 className="recruiter-signup__title">RECRUITER</h1>
          <div className="recruiter-signup__content-wrapper">
            <div className="recruiter-signup__content">
              <div className="recruiter-signup__brackets">
                <div className="recruiter-signup__bracket-left">{"{"}</div>
                <div className="recruiter-signup__text-wrapper">
                  <h2 className="recruiter-signup__subtitle">SIGN UP</h2>
                </div>
                <div className="recruiter-signup__bracket-right">{"}"}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="recruiter-signup__container">
        <div className="recruiter-signup__formwrapper">
          <form className="recruiter-signup__form" onSubmit={handleSubmit}>
            <div className="recruiter-signup__columns-box">
              <div className="recruiter-signup__left-column">
                <div className="recruiter-signup__form-field">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                    className="recruiter-signup__input"
                    required
                  />
                </div>
                <div className="recruiter-signup__form-field">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                    className="recruiter-signup__input"
                    required
                  />
                </div>
                <div className="recruiter-signup__form-field">
                  <label htmlFor="jobTitle">Job Title</label>
                  <input
                    type="text"
                    id="jobTitle"
                    name="jobTitle"
                    value={formData.jobTitle}
                    onChange={handleChange}
                    placeholder="Job Title"
                    className="recruiter-signup__input"
                    required
                  />
                </div>
                <div className="recruiter-signup__form-field">
                  <label htmlFor="company">Company</label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    placeholder="company"
                    className="recruiter-signup__input"
                    autoComplete="organization"
                    required
                  />
                </div>

                <div className="recruiter-signup__form-field">
                  <label htmlFor="sector">Sector</label>
                  <input
                    type="text"
                    id="sector"
                    name="sector"
                    value={formData.sector}
                    onChange={handleChange}
                    placeholder="Company"
                    className="recruiter-signup__input"
                    required
                  />
                </div>

                <div className="recruiter-signup__form-field">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    placeholder="Address"
                    className="recruiter-signup__input"
                    required
                    autoComplete="street-address"
                  />
                </div>
              </div>

              <div className="recruiter-signup__right-column">
                <div className="recruiter-signup__form-field">
                  <label htmlFor="locationCity">Location</label>
                  <input
                    type="text"
                    id="locationCity"
                    name="locationCity"
                    value={formData.locationCity}
                    onChange={handleChange}
                    placeholder="City"
                    className="recruiter-signup__input"
                    required
                  />
                </div>
                <div className="recruiter-signup__form-field">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone"
                    className="recruiter-signup__input"
                    required
                    autoComplete="tel"
                  />
                </div>
                <div className="recruiter-signup__form-field">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    className="recruiter-signup__input"
                    required
                    autoComplete="username"
                  />
                </div>

                <div className="recruiter-signup__form-field">
                  <label htmlFor="setPassword">Set Password</label>
                  <input
                    type="password"
                    id="setPassword"
                    name="setPassword"
                    value={formData.setPassword}
                    onChange={handleChange}
                    placeholder="Set Password"
                    className="recruiter-signup__input"
                    required
                    autoComplete="new-password"
                  />
                </div>
                <div className="recruiter-signup__form-field recruiter-signup__form-field-password check">
                  <label htmlFor="checkPassword">Check Password</label>
                  <input
                    type="password"
                    id="checkPassword"
                    name="checkPassword"
                    value={formData.checkPassword}
                    onChange={handleChange}
                    placeholder="Check Password"
                    className="recruiter-signup__input"
                    required
                    autoComplete="new-password"
                  />
                  {passwordError && (
                    <div className="recruiter-signup__error-message">
                      Passwords do not match. Please re-confirm your password.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="recruiter-signup__columns-box-two">
              <div className="recruiter-signup__button-wrapper">
                <button type="submit" className="recruiter-signup__button">
                  Sign Up
                </button>
              </div>
              {errorMessage && (
                <div className="recruiter-signup__error-message">
                  {errorMessage}
                </div>
              )}
              {successMessage && (
                <div className="recruiter-signup__success-message">
                  {successMessage}
                </div>
              )}

              <div className="recruiter-signup__login-link-wrapper">
                <p className="recruiter-signup__login-link-terms">
                  TERMS: By signing up to CODERATI, you agree that you will only
                  use jobseekers' personal details for legitimate and
                  professional recruitment purposes and you agree not to
                  disseminate or publish any personal details that appear on
                  CODERATI on any other platforms whether on-line or in print.{" "}
                </p>
                <p className="recruiter-signup__login-link">
                  Already have an account?{" "}
                  <Link
                    to="/signin"
                    className="recruiter-signup__login-link-special"
                  >
                    Sign In
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default RecruiterSignUp;
