import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./SigninPage.scss";
import awsConfig from "../../awsConfig/awsConfig.json";

const Signin = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [userType, setUserType] = useState("jobSeeker");
  const [errorMessage, setErrorMessage] = useState("");
  const [deletionErrorMessage, setDeletionErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const glitterVideo = `${awsConfig.s3BucketUrl}${awsConfig.videoPath}`;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });

    if (errorMessage) {
      setErrorMessage("");
    }
  };

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };

  const openEyeUrl = `${process.env.REACT_APP_API_URL}/icons/openeyelogo.png`;
  const closedEyeUrl = `${process.env.REACT_APP_API_URL}/icons/closedeyelogo.png`;
  const handleSubmit = async (event) => {
    event.preventDefault();

    localStorage.removeItem("token");

    const signInUrl =
      userType === "jobSeeker"
        ? `${process.env.REACT_APP_API_URL}/auth/jobSeekerSignIn`
        : `${process.env.REACT_APP_API_URL}/auth/recruiterSignIn`;

    try {
      const response = await axios.post(signInUrl, formData);

      if (typeof response.data.token === "string") {
        localStorage.setItem("token", response.data.token);
      } else {
        console.error("Received token is not a string:", response.data.token);
      }

      if (userType === "jobSeeker") {
        navigate("/searchchoices");
      } else {
        navigate("/recruitersearchchoices");
      }
    } catch (error) {
      console.error("Sign-in error", error);
      setErrorMessage("Incorrect email or password. Please try again.");
    }
  };

  const handleSumbitUpdate = async (event) => {
    event.preventDefault();

    localStorage.removeItem("token");

    const signInUrl =
      userType === "jobSeeker"
        ? `${process.env.REACT_APP_API_URL}/auth/jobSeekerSignIn`
        : `${process.env.REACT_APP_API_URL}/auth/recruiterSignIn`;

    try {
      const response = await axios.post(signInUrl, formData);

      if (typeof response.data.token === "string") {
        localStorage.setItem("token", response.data.token);
      } else {
        console.error("Received token is not a string:", response.data.token);
      }

      if (userType === "jobSeeker") {
        navigate("/updateJobSeeker");
      } else {
        navigate("/updateRecruiter");
      }
    } catch (error) {
      console.error("Sign-in error", error);
      setErrorMessage("Incorrect email or password. Please try again.");
    }
  };

  const handleDeleteProfile = async () => {
    const signInUrl =
      userType === "jobSeeker"
        ? `${process.env.REACT_APP_API_URL}/auth/jobSeekerSignIn`
        : `${process.env.REACT_APP_API_URL}/auth/recruiterSignIn`;

    try {
      const authResponse = await axios.post(signInUrl, {
        email: formData.email,
        password: formData.password,
      });
      localStorage.setItem("token", authResponse.data.token);

      const deleteUrl =
        userType === "jobSeeker"
          ? `${process.env.REACT_APP_API_URL}/auth/deleteJobSeekerProfile`
          : `${process.env.REACT_APP_API_URL}/auth/deleteRecruiterProfile`;

      const deleteResponse = await axios.delete(deleteUrl, {
        headers: {
          Authorization: `Bearer ${authResponse.data.token}`,
        },
      });

      if (deleteResponse.status === 200) {
        setSuccessMessage(`${deleteResponse.data.message}`);
        setTimeout(() => {
          localStorage.removeItem("token");
          navigate("/");
        }, 1000);
      } else {
        console.error(
          "Delete profile error, status code:",
          deleteResponse.status
        );
        setDeletionErrorMessage(
          deleteResponse.data.message ||
            "Failed to delete profile. Please try again."
        );
      }
    } catch (error) {
      console.error("Delete profile error", error.response?.data);
      setDeletionErrorMessage(
        error.response?.data.message ||
          "Failed to delete profile. Please try again."
      );
    }
  };

  return (
    <section className="signin">
      <div className="signin__background-video">
        <video
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          style={{ opacity: 0, transition: "opacity 0.5s ease" }}
          onLoadedData={(event) => {
            event.target.style.opacity = 1;
          }}
        >
          <source src={glitterVideo} type="video/mp4" />
        </video>
      </div>

      <div className="signin__content">
        <h1 className="signin__title">CODERATI</h1>

        <div className="signin__brackets">
          <div className="signin__bracket-left">{"{"}</div>
          <div className="signin__subtitle-box">
            <h2 className="signin__subtitle-text">
              SIGN <span className="signin__subtitle-text-highlight">IN</span>
            </h2>
          </div>
          <div className="signin__bracket-right">{"}"}</div>
        </div>

        <div className="signin__form-wrapper">
          <form className="signin__form" onSubmit={handleSubmit}>
            <div className="signin__user-type-selection">
              <input
                className="signin__userchoice-radio"
                type="radio"
                id="jobSeeker"
                name="userType"
                value="jobSeeker"
                checked={userType === "jobSeeker"}
                onChange={handleUserTypeChange}
              />
              <label className="signin__userlabel" htmlFor="jobSeeker">
                Job Seeker
              </label>

              <input
                className="signin__userchoice-radio"
                type="radio"
                id="recruiter"
                name="userType"
                value="recruiter"
                checked={userType === "recruiter"}
                onChange={handleUserTypeChange}
              />
              <label className="signin__userlabel" htmlFor="recruiter">
                Recruiter
              </label>
            </div>

            <div className="signin__form-field-wrapper">
              <div className="signin__form-field">
                <label className="signin__form-field-label" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  className="signin__text-input"
                  autoComplete="email"
                  required
                />
              </div>

              <div className="signin__form-field">
                <label className="signin__form-field-label" htmlFor="password">
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password"
                  className="signin__text-input"
                  autoComplete="current-password"
                  required
                />
                <img
                  src={showPassword ? openEyeUrl : closedEyeUrl}
                  alt="Toggle Password Visibility"
                  className="signin__toggle-password"
                  onClick={togglePasswordVisibility}
                />
              </div>

              <div className="signin__button-wrapper">
                <button className="signin__button" type="submit">
                  SIGN IN
                </button>
              </div>
              <div className="signin__error-message-wrapper">
                {errorMessage && (
                  <div className="signin__error-message">{errorMessage}</div>
                )}
              </div>

              <div className="signin__button-wrapper">
                <button
                  className="signin__andupdatebutton"
                  onClick={handleSumbitUpdate}
                >
                  SIGN IN AND UPDATE PROFILE
                </button>
              </div>
              <div className="signin__button-wrapper signin__button-wrapper-special">
                <button
                  className="signin__andupdatebutton signin__anddeletebutton"
                  onClick={(event) => {
                    event.preventDefault();
                    handleDeleteProfile();
                  }}
                >
                  REMOVE YOUR PROFILE
                </button>
                <div>
                  {deletionErrorMessage && (
                    <div className="signin__error-message">
                      {deletionErrorMessage}
                    </div>
                  )}
                  {successMessage && (
                    <div className="signin__delete-success-message">
                      {successMessage}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Signin;
