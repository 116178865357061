import React from "react";
import { Link } from "react-router-dom";
import "./AboutPage.scss";
import awsConfig from "../../awsConfig/awsConfig.json";

const About = () => {
  const glitterVideo = `${awsConfig.s3BucketUrl}${awsConfig.videoPath}`;
  return (
    <section className="about">
      <div className="about__wrapper">
        <div className="about__top-box">
          <div className="about__background-video">
            <video
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              style={{ opacity: 0, transition: "opacity 0.5s ease" }}
              onLoadedData={(event) => {
                event.target.style.opacity = 1;
              }}
            >
              <source src={glitterVideo} type="video/mp4" />
            </video>

            <h1 className="about__title">ABOUT</h1>
          </div>
        </div>
        <div className="about__article-wrapper">
          <div className="about__article">
            <p className="about__text">
              <span className="about__logo">CODERATI</span> is an application
              that connects skilled coders with the tech companies that need to
              hire them. It's deliberately designed to serve a specific niche:
              recruitment in software enginnering, design, and development.
            </p>
            <p className="about__text">
              The app is a one-stop-shop - where users can search for either
              talent or jobs.
            </p>
            <p className="about__text">
              Coders can search for 'live' jobs posted anywhere in the world.
              Plus, when they upload their CVs, details, and skill sets, tech
              recruiters have access a single talent pool, made up exclusively
              of individuals skilled in the field they are hiring for.
            </p>
            <p className="about__text">
              When recruiters post jobs on the CODERARI website, their ads reach
              their desired target audience directly.
            </p>
            <p className="about__text"> * * *</p>
            <p className="about__text">
              CODERATI is currently in a prototype testing stage, where it is
              free to use. Depending on uptake, it may be developed into a
              reasonably priced subscription model.
            </p>
            <p className="about__text"> * * *</p>

            <p className="about__text">
              DISCLAIMER: while the job search page is 'live' and genuinely
              fetches currently posted job adverts, job adverts posted directly
              on CODERATI are currently mock data for testing purposes.
              Likewise, some of the individuals who appear in talent searches
              are currently also mock data - generated by AI. All the mock data
              will be replaced by real data as and when users sign up.
            </p>
          </div>
          <div className="about__button-wrapper">
            <Link to="/">
              <button className="about__button">BACK TO HOME</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
