import React from "react";
import { Link } from "react-router-dom";
import "./ContactPage.scss";
import awsConfig from "../../awsConfig/awsConfig.json";

const ContactPage = () => {
  const glitterVideo = `${awsConfig.s3BucketUrl}${awsConfig.videoPath}`;
  return (
    <section className="contact">
      <div className="contact__top-box">
        <div className="contact__background-video">
          <video
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            style={{ opacity: 0, transition: "opacity 0.5s ease" }}
            onLoadedData={(event) => {
              event.target.style.opacity = 1;
            }}
          >
            <source src={glitterVideo} type="video/mp4" />
          </video>
        </div>
      </div>
      <h1 className="contact__title">CODERATI</h1>
      <div className="contact__content-wrapper">
        <div className="contact__content">
          <div className="contact__brackets">
            <div className="contact__bracket-left">{"{"}</div>
            <div className="contact__mission-statement">
              <h2 className="contact__mission-statement-text">
                Helping
                <span className="contact__mission-statement-text contact__tech-companies">
                  {" "}
                  tech COMPANIES{" "}
                </span>
                find
              </h2>
              <h2 className="contact__mission-statement-text">
                highly skilled coders
              </h2>
            </div>
            <div className="contact__bracket-right">{"}"}</div>
          </div>
        </div>
      </div>
      <div className="contact__button-wrapper">
        <Link to="/signup">
          <button className="contact__get-started-button">
            GET <span className="contact__highlighted-text">STARTED</span>
          </button>
        </Link>
      </div>
      <div className="contact__link-wrapper">
        <div className="contact__info">
          <h2 className="contact__details">
            <a
              href="mailto:michaelpatrickmcdowall@gmail.com?subject=CODERATI%20enquiry/feedback"
              className="contact__contact-button"
            >
              For more information or to send feedback click here
            </a>
          </h2>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
