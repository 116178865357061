import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./TalentSearchPage.scss";
import awsConfig from "../../awsConfig/awsConfig.json";

function parseJwt(token) {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (error) {
    console.error("Failed to decode JWT:", error);
    return null;
  }
}

const TalentSearchPage = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const [jobTitle, setJobTitle] = useState([]);
  const [keySkills, setKeySkills] = useState([]);
  const [locationCity, setLocationCity] = useState([]);
  const [experience, setExperience] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [redirectMessage, setRedirectMessage] = useState("");
  const [nameSearch, setNameSearch] = useState("");
  const [role, setRole] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState({
    jobTitle: false,
    keySkills: false,
    locationCity: false,
    experience: false,
  });
  const navigate = useNavigate();
  const scrollToRef = useRef();
  const scrollToError = useRef();
  const glitterVideo = `${awsConfig.s3BucketUrl}${awsConfig.videoPath}`;

  useEffect(() => {
    const tokenString = localStorage.getItem("token");
    if (!tokenString) {
      setRedirectMessage("You must sign in before searching. Redirecting...");
      setTimeout(() => {
        navigate("/signin");
      }, 1200);
    } else {
      const decodedToken = parseJwt(tokenString);
      if (decodedToken) {
        setRole(decodedToken.role);
      } else {
        console.error("Invalid token, redirecting to sign-in...");
        setRedirectMessage(
          "Invalid authentication. Please sign in again. Redirecting..."
        );
        setTimeout(() => {
          navigate("/signin");
        }, 1200);
      }
    }
  }, [navigate]);

  const jobTitlesList = [
    "Full Stack Developer",
    "Senior Full Stack Developer",
    "Junior Full Stack Developer",
    "Frontend Developer",
    "Senior Frontend Developer",
    "Junior Frontend Developer",
    "Backend Developer",
    "Senior Backend Developer",
    "Junior Backend Developer",
    "Software Developer",
    "Senior Software Developer",
    "Junior Software Developer",
    "Software Engineer",
    "Senior Software Engineer",
    "Junior Software Engineer",
    "UX Designer",
    "UI Designer",
    "Senior UI/UX Designer",
    "Junior UI/UX Designer",
    "Data Scientist",
    "Senior Data Scientist",
    "Junior Data Scientist",
    "Data Engineer",
  ];

  const keySkillsList = [
    "Javascript",
    "Java",
    "Python",
    "HTML",
    "SCSS",
    "CSS",
    "C#",
    "C++",
    "React",
    "Node.js",
    "MongoDB",
    "Ruby",
    "SQL",
    "MySQL",
    "PostgreSql",
    ".NET",
    "Angular",
    "Sketch",
    "Figma",
    "NUMpy",
    "User Research",
  ];

  const locationCitiesList = [
    "London",
    "Manchester",
    "Liverpool",
    "Glasgow",
    "Oxford",
    "Cambridge",
    "Southampton",
    "Cardiff",
    "Leicester",
    "Birmingham",
    "Nottingham",
    "Brighton",
    "Newcastle",
    "Edinburgh",
    "Leeds",
    "Sheffield",
    "Bristol",
    "Portsmouth",
  ];

  const experienceList = [
    "Graduate",
    "1 year",
    "2 years",
    "3 years",
    "4 years",
    "5 years",
    "5+ years",
  ];

  const handleCheckboxChange = (event, setState) => {
    const value = event.target.value;

    setState((prev) => {
      if (prev.includes(value)) {
        return prev.filter((individual) => individual !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const toggleDropdown = (dropdown) => {
    setDropdownOpen((prev) => ({
      ...prev,
      [dropdown]: !prev[dropdown],
    }));
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setSearchSubmitted(true);

    let apiUrl = `${process.env.REACT_APP_API_URL}/talentsearch/talentsearch`;
    const token = localStorage.getItem("token");

    let params = {
      jobTitle: jobTitle.join(","),
      locationCity: locationCity.join(","),
      keySkills: keySkills.join(","),
      experience: experience.join(","),
    };

    if (nameSearch.trim()) {
      apiUrl = `${process.env.REACT_APP_API_URL}/talentsearch/name`;
      params = { name: nameSearch.trim() };
    }

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },

        params,
      });

      const talent = response.data;

      if (talent.message) {
        setErrorMessage(talent.message);
        setSearchResults([]);
      } else {
        setSearchResults(talent);
        setErrorMessage(null);

        setTimeout(() => {
          scrollToRef.current?.scrollIntoView();
        }, 0);
      }
    } catch (error) {
      console.error("Error fetching talent data", error);
      setSearchResults([]);
      setErrorMessage("Error occurred fetching talent data.");
    }
  };

  if (redirectMessage) {
    return <div className="redirect-message">{redirectMessage}</div>;
  }

  return (
    <section className="talent">
      <div className="talent__title-box">
        <div className="talent__background-video">
          <video
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            style={{ opacity: 0, transition: "opacity 0.5s ease" }}
            onLoadedData={(event) => {
              event.target.style.opacity = 1;
            }}
          >
            <source src={glitterVideo} type="video/mp4" />
          </video>
          <div className="talent__title-container">
            <h1 className="talent__title">TALENT</h1>
          </div>
        </div>
      </div>
      <form className="talent__form" onSubmit={handleSearch}>
        <div className="talent__form-wrapper">
          <h2 className="talent__subtitle">SELECT SEARCH CRITERIA</h2>

          <div className="talent__form-selects">
            <div className="talent__select-row">
              <div className="talent__leftBoxes">
                <div className="talent__input-box">
                  <label
                    className="talent__input-label"
                    htmlFor="jobTitle-select"
                  >
                    Job Title
                  </label>
                  <div className="custom-select-container">
                    <button
                      type="button"
                      className={`custom-select-button ${dropdownOpen.jobTitle ? "open" : ""}`}
                      onClick={() => toggleDropdown("jobTitle")}
                      id="jobTitle-select"
                    >
                      Select Job Titles
                    </button>
                    {dropdownOpen.jobTitle && (
                      <div className="custom-select-dropdown">
                        {jobTitlesList.map((title, index) => (
                          <label
                            key={title}
                            className="custom-select-option"
                            htmlFor={`jobTitle-${index}`}
                          >
                            <input
                              className="custom-select-option-checkbox"
                              type="checkbox"
                              id={`jobTitle-${index}`}
                              name="jobTitle"
                              value={title}
                              checked={jobTitle.includes(title)}
                              onChange={(e) =>
                                handleCheckboxChange(e, setJobTitle)
                              }
                            />
                            {title}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="talent__input-box">
                  <label
                    className="talent__input-label"
                    htmlFor="keySkills-select"
                  >
                    Key Skills
                  </label>
                  <div className="custom-select-container">
                    <button
                      type="button"
                      className={`custom-select-button ${dropdownOpen.keySkills ? "open" : ""}`}
                      onClick={() => toggleDropdown("keySkills")}
                      id="keySkills-select"
                    >
                      Select Key Skills
                    </button>
                    {dropdownOpen.keySkills && (
                      <div className="custom-select-dropdown">
                        {keySkillsList.map((skill, index) => (
                          <label
                            key={skill}
                            className="custom-select-option"
                            htmlFor={`keySkill-${index}`}
                          >
                            <input
                              type="checkbox"
                              id={`keySkill-${index}`}
                              name="keySkills"
                              value={skill}
                              checked={keySkills.includes(skill)}
                              onChange={(e) =>
                                handleCheckboxChange(e, setKeySkills)
                              }
                            />
                            {skill}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="talent__rightBoxes">
                <div className="talent__input-box">
                  <label
                    className="talent__input-label"
                    htmlFor="locationCity-select"
                  >
                    Location
                  </label>
                  <div className="custom-select-container">
                    <button
                      type="button"
                      className={`custom-select-button ${dropdownOpen.locationCity ? "open" : ""}`}
                      onClick={() => toggleDropdown("locationCity")}
                      id="locationCity-select"
                    >
                      Select Locations
                    </button>
                    {dropdownOpen.locationCity && (
                      <div className="custom-select-dropdown">
                        {locationCitiesList.map((location, index) => (
                          <label
                            key={location}
                            className="custom-select-option"
                            htmlFor={`locationCity-${index}`}
                          >
                            <input
                              type="checkbox"
                              id={`locationCity-${index}`}
                              name="locationCity"
                              value={location}
                              checked={locationCity.includes(location)}
                              onChange={(e) =>
                                handleCheckboxChange(e, setLocationCity)
                              }
                            />
                            {location}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="talent__input-box">
                  <label
                    className="talent__input-label"
                    htmlFor="experience-select"
                  >
                    Experience
                  </label>
                  <div className="custom-select-container">
                    <button
                      type="button"
                      className={`custom-select-button ${dropdownOpen.experience ? "open" : ""}`}
                      onClick={() => toggleDropdown("experience")}
                      id="experience-select"
                    >
                      Select Experience
                    </button>
                    {dropdownOpen.experience && (
                      <div className="custom-select-dropdown">
                        {experienceList.map((exp, index) => (
                          <label
                            key={exp}
                            className="custom-select-option"
                            htmlFor={`experience-${index}`}
                          >
                            <input
                              type="checkbox"
                              id={`experience-${index}`}
                              name="experience"
                              value={exp}
                              checked={experience.includes(exp)}
                              onChange={(e) =>
                                handleCheckboxChange(e, setExperience)
                              }
                            />
                            {exp}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2 className="talent__subtitle">OR SEARCH BY NAME</h2>

          <div className="talent__input-box-special">
            <input
              type="text"
              id="nameSearch"
              name="nameSearch"
              value={nameSearch}
              onChange={(event) => setNameSearch(event.target.value)}
              placeholder="Enter First Name and Last Name..."
              className="talent__nameSearchField"
            />
          </div>

          <div className="talent__button-wrapper">
            <button className="talent__button" type="submit">
              SEARCH
            </button>
          </div>
        </div>
      </form>

      <div ref={scrollToRef} className="talent__results-window">
        <div className="talent__card-wrapper">
          {searchResults.length > 0
            ? searchResults.map((talent) => (
                <div key={talent.id} className="talent__card-container">
                  <div className="talent__card">
                    <div className="talent__name-banner">
                      <img
                        className="talent__headshot"
                        src={talent.image}
                        alt={`${talent.firstName} ${talent.lastName}`}
                      />
                      <h2 className="talent__name">{`${talent.firstName} ${talent.lastName}`}</h2>
                    </div>
                    <div className="talent__info-box">
                      <p className="talent__detail-jobTitle">
                        {talent.jobTitle}
                      </p>
                      <p className="talent__detail-location">
                        {talent.locationCity}
                      </p>
                      <p className="talent__detail-subtitle">SKILLS</p>
                      <p className="talent__detail">{talent.keySkills}</p>
                      <p className="talent__detail-subtitle">EXPERIENCE</p>
                      <p className="talent__detail">{talent.experience}</p>
                      <p className="talent__detail-subtitle">PREVIOUS ROLES</p>
                      <p className="talent__detail">{talent.previousRoles}</p>
                      <p className="talent__detail-subtitle">ACHIEVEMENTS</p>
                      {talent.achievements &&
                        talent.achievements
                          .split("\n")
                          .map((achievement, index) => (
                            <p key={index} className="talent__detail">
                              {achievement}
                            </p>
                          ))}

                      {role.toLowerCase() === "jobseeker" && (
                        <div className="talent__linksbox-jobseeker">
                          <div className="talent__detail-group">
                            <p className="talent__detail-subtitle">PORTFOLIO</p>
                            {talent.portfolio ? (
                              <a
                                href={talent.portfolio}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="talent__link"
                              >
                                Click here
                              </a>
                            ) : (
                              <p className="talent__link">N/A</p>
                            )}
                          </div>
                          <div className="talent__detail-group">
                            <p className="talent__detail-subtitle">GITHUB</p>
                            {talent.gitHub ? (
                              <a
                                href={talent.gitHub}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="talent__link"
                              >
                                Click here
                              </a>
                            ) : (
                              <p className="talent__link">N/A</p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {role === "recruiter" && (
                      <div className="talent__sensitive-info-block">
                        <div className="talent__linksbox">
                          <div className="talent__detail-group">
                            <p className="talent__detail-subtitle">PORTFOLIO</p>
                            {talent.portfolio ? (
                              <a
                                href={talent.portfolio}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="talent__link"
                              >
                                Click here
                              </a>
                            ) : (
                              <p className="talent__link">N/A</p>
                            )}
                          </div>
                          <div className="talent__detail-group">
                            <p className="talent__detail-subtitle">GITHUB</p>
                            {talent.gitHub ? (
                              <a
                                href={talent.gitHub}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="talent__link"
                              >
                                Click here
                              </a>
                            ) : (
                              <p className="talent__link">N/A</p>
                            )}
                          </div>
                          <div className="talent__detail-group">
                            <p className="talent__detail-subtitle">
                              RESUME / CV
                            </p>
                            {talent.resume ? (
                              <a
                                href={talent.resume}
                                download
                                className="talent__link"
                              >
                                Click to download
                              </a>
                            ) : (
                              <p className="talent__link">N/A</p>
                            )}
                          </div>
                        </div>
                        <p className="talent__detail-subtitle">CONTACT</p>
                        <p className="talent__detail">{talent.email}</p>
                        <p className="talent__detail">{talent.phone}</p>
                      </div>
                    )}
                  </div>
                </div>
              ))
            : errorMessage && (
                <div
                  ref={scrollToError}
                  className="talent__failed-search-message"
                >
                  <h2>{errorMessage}</h2>
                </div>
              )}
          {searchSubmitted && role === "jobseeker" && (
            <p className="talent__authorization-notice">
              Only Authorized Recruiters have access to contact details and CVs
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default TalentSearchPage;
