import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import "./UpdateJobSeeker.scss";
import awsConfig from "../../awsConfig/awsConfig.json";
function UpdateJobSeekerProfile() {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [fileData, setFileData] = useState({ image: null, resume: null });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    keySkills: "",
    address: "",
    locationCity: "",
    phone: "",
    email: "",
    experience: "",
    previousRoles: "",
    achievements: "",
    portfolio: "",
    gitHub: "",
  });
  const glitterVideo = `${awsConfig.s3BucketUrl}${awsConfig.videoPath}`;
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodedToken = jwtDecode(token);
        const userIdFromToken = decodedToken.userId;

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/jobseeker/jobseeker/${userIdFromToken}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const userData = response.data;
        setFormData(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem("token");
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.userId;

      const updates = {
        userId: userId,
        ...formData,
      };

      await axios.patch(
        `${process.env.REACT_APP_API_URL}/jobSeeker/updateJobSeeker`,
        updates,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSuccessMessage("Data sent successfully!");
    } catch (error) {
      console.error("Error updating profile:", error);
      setSuccessMessage("");
    }

    try {
      await handleFileUpload();
    } catch (error) {
      console.error("There was a problem submitting files:", error);
    } finally {
      navigate("/searchchoices");
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    if (files.length > 0) {
      setFileData((prevFileData) => ({
        ...prevFileData,
        [name]: files[0],
      }));
    }
  };
  const handleFileUpload = async () => {
    if (!fileData.image && !fileData.resume) {
      return;
    }

    const token = localStorage.getItem("token");
    const formData = new FormData();
    if (fileData.image) formData.append("image", fileData.image);
    if (fileData.resume) formData.append("resume", fileData.resume);

    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/jobSeeker/updateJobSeeker`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  return (
    <section className="jobseeker-update-profile">
      <div className="jobseeker-update-profile__title-box">
        <div className="jobseeker-update-profile__background-video">
          <video
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            style={{ opacity: 0, transition: "opacity 0.5s ease" }}
            onLoadedData={(event) => {
              event.target.style.opacity = 1;
            }}
          >
            <source src={glitterVideo} type="video/mp4" />
          </video>
          <h1 className="jobseeker-update-profile__title">
            UPDATE TALENT PROFILE
          </h1>
          <div className="jobseeker-update-profile__content-wrapper">
            <div className="jobseeker-update-profile__content">
              <div className="jobseeker-update-profile__brackets">
                <div className="jobseeker-update-profile__bracket-left">
                  {"{"}
                </div>
                <div className="jobseeker-update-profile__text-wrapper">
                  <h2 className="jobseeker-update-profile__subtitle">
                    CHANGE ANY FIELD
                  </h2>
                </div>
                <div className="jobseeker-update-profile__bracket-right">
                  {"}"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="jobseeker-update-profile__container">
          <div className="jobseeker-update-profile__formwrapper">
            <form
              className="jobseeker-update-profile__form"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="jobseeker-update-profile__columns-box">
                <div className="jobseeker-update-profile__left-column">
                  <div className="jobseeker-update-profile__form-field">
                    <label
                      className="jobseeker-update-profile__label"
                      htmlFor="firstName"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder="First Name"
                      className="jobseeker-update-profile__input"
                      autoComplete="given-name"
                    />
                  </div>

                  <div className="jobseeker-update-profile__form-field">
                    <label
                      className="jobseeker-update-profile__label"
                      htmlFor="lastName"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      placeholder="Last Name"
                      className="jobseeker-update-profile__input"
                      autoComplete="family-name"
                    />
                  </div>

                  <div className="jobseeker-update-profile__form-field">
                    <label
                      className="jobseeker-update-profile__label"
                      htmlFor="jobTitle"
                    >
                      Job Title
                    </label>
                    <input
                      type="text"
                      id="jobTitle"
                      name="jobTitle"
                      value={formData.jobTitle}
                      onChange={handleChange}
                      placeholder="Job Title"
                      className="jobseeker-update-profile__input"
                    />
                  </div>

                  <div className="jobseeker-update-profile__form-field">
                    <label
                      className="jobseeker-update-profile__label"
                      htmlFor="keySkills"
                    >
                      Key Skills
                    </label>
                    <input
                      type="text"
                      id="keySkills"
                      name="keySkills"
                      value={formData.keySkills}
                      onChange={handleChange}
                      placeholder="Key Skills"
                      className="jobseeker-update-profile__input"
                    />
                  </div>

                  <div className="jobseeker-update-profile__form-field">
                    <label
                      className="jobseeker-update-profile__label"
                      htmlFor="address"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      placeholder="Address"
                      className="jobseeker-update-profile__input"
                      autoComplete="street-address"
                    />
                  </div>

                  <div className="jobseeker-update-profile__form-field">
                    <label
                      className="jobseeker-update-profile__label"
                      htmlFor="locationCity"
                    >
                      Location City
                    </label>
                    <input
                      type="text"
                      id="locationCity"
                      name="locationCity"
                      value={formData.locationCity}
                      onChange={handleChange}
                      placeholder="Location City"
                      className="jobseeker-update-profile__input"
                    />
                  </div>

                  <div className="jobseeker-update-profile__form-field">
                    <label
                      className="jobseeker-update-profile__label"
                      htmlFor="phone"
                    >
                      Phone
                    </label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Phone"
                      className="jobseeker-update-profile__input"
                      autoComplete="tel"
                    />
                  </div>

                  <div className="jobseeker-update-profile__form-field">
                    <label
                      className="jobseeker-update-profile__label"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email"
                      className="jobseeker-update-profile__input"
                      autoComplete="username"
                    />
                  </div>

                  <div className="jobseeker-update-profile__form-field">
                    <label
                      className="jobseeker-update-profile__label"
                      htmlFor="experience"
                    >
                      Experience
                    </label>
                    <textarea
                      id="experience"
                      name="experience"
                      value={formData.experience}
                      onChange={handleChange}
                      placeholder="Number of years in current role"
                      className="jobseeker-update-profile__input jobseeker-update-profile__experience"
                    />
                  </div>
                </div>
                <div className="jobseeker-update-profile__right-column">
                  <div className="jobseeker-update-profile__form-field">
                    <label
                      className="jobseeker-update-profile__label"
                      htmlFor="previousRoles"
                    >
                      Previous Roles
                    </label>
                    <input
                      type="text"
                      id="previousRoles"
                      name="previousRoles"
                      value={formData.previousRoles}
                      onChange={handleChange}
                      placeholder="Previous Roles"
                      className="jobseeker-update-profile__input"
                    />
                  </div>
                  <div className="jobseeker-update-profile__form-field jobseeker-update-profile__text-area">
                    <label
                      className="jobseeker-update-profile__label"
                      htmlFor="achievements"
                    >
                      Achievements
                    </label>
                    <textarea
                      id="achievements"
                      name="achievements"
                      value={formData.achievements}
                      onChange={handleChange}
                      placeholder="Achievements"
                      className="jobseeker-update-profile__input jobseeker-update-profile__achievements"
                    />
                  </div>

                  <div className="jobseeker-update-profile__form-field">
                    <label
                      className="jobseeker-update-profile__label"
                      htmlFor="portfolio"
                    >
                      Portfolio
                    </label>
                    <input
                      type="text"
                      id="portfolio"
                      name="portfolio"
                      value={formData.portfolio}
                      onChange={handleChange}
                      placeholder="Portfolio Link"
                      className="jobseeker-update-profile__input"
                    />
                  </div>

                  <div className="jobseeker-update-profile__form-field">
                    <label
                      className="jobseeker-update-profile__label"
                      htmlFor="gitHub"
                    >
                      GitHub Profile
                    </label>
                    <input
                      type="text"
                      id="gitHub"
                      name="gitHub"
                      value={formData.gitHub}
                      onChange={handleChange}
                      placeholder="GitHub Link"
                      className="jobseeker-update-profile__input"
                    />
                  </div>

                  <div className="jobseeker-update-profile__form-field">
                    <label
                      className="jobseeker-update-profile__label"
                      htmlFor="image"
                    >
                      Upload Photo (optional)
                    </label>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      accept="image/*"
                      onChange={handleFileChange}
                      className="jobseeker-update-profile__input"
                    />
                  </div>
                  <div className="jobseeker-update-profile__form-field">
                    <label
                      className="jobseeker-update-profile__label"
                      htmlFor="resume"
                    >
                      Upload CV / Resume (optional)
                    </label>
                    <input
                      type="file"
                      id="resume"
                      name="resume"
                      accept=".pdf,.doc,.docx"
                      onChange={handleFileChange}
                      className="jobseeker-update-profile__input"
                    />
                  </div>
                </div>
              </div>

              {successMessage && (
                <div className="jobseeker-update-profile__success-message">
                  {successMessage}
                </div>
              )}
              <div className="jobseeker-update-profile__button-wrapper">
                <div className="jobseeker-update-profile__columns-box-two">
                  <button
                    type="submit"
                    className="jobseeker-update-profile__button"
                  >
                    Update Profile
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UpdateJobSeekerProfile;
