import React from "react";
import { Link } from "react-router-dom";
import "./SearchChoicesPage.scss";
import awsConfig from "../../awsConfig/awsConfig.json";

const SearchChoices = () => {
  const glitterVideo = `${awsConfig.s3BucketUrl}${awsConfig.videoPath}`;
  return (
    <section className="search-choices">
      <div className="search-choices__titlebox">
        <div className="search-choices__background-video">
          <video
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            style={{ opacity: 0, transition: "opacity 0.5s ease" }}
            onLoadedData={(event) => {
              event.target.style.opacity = 1;
            }}
          >
            <source src={glitterVideo} type="video/mp4" />
          </video>
        </div>
        <h1 className="search-choices__title">CODERATI</h1>

        <div className="search-choices__buttons">
          <Link to="/extsearch">
            <button className="search-choices__button">Search Jobs</button>
          </Link>

          <Link to="/talentsearch">
            <button className="search-choices__button">Search Talent</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SearchChoices;
