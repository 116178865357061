import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./SignupPage.scss";
import awsConfig from "../../awsConfig/awsConfig.json";

const Signup = () => {
  const [userType, setUserType] = useState("");
  const [showError, setShowError] = useState(false);
  const glitterVideo = `${awsConfig.s3BucketUrl}${awsConfig.videoPath}`;

  const handleUserTypeChange = (newUserType) => {
    setUserType(newUserType);
    setShowError(false);
  };

  const handleContinueClick = () => {
    if (userType === "") {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  return (
    <section className="signup">
      <div className="signup__background-video">
        <video
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          style={{ opacity: 0, transition: "opacity 0.5s ease" }}
          onLoadedData={(event) => {
            event.target.style.opacity = 1;
          }}
        >
          <source src={glitterVideo} type="video/mp4" />
        </video>
      </div>

      <div className="signup__scroll-wrapper">
        <h1 className="signup__title">CODERATI</h1>
        <div className="signup__content-wrapper">
          <div className="signup__content">
            <div className="signup__brackets">
              <div className="signup__bracket-left">{"{"}</div>
              <div className="signup__subtitle-box">
                <h2 className="signup__subtitle-text">
                  SIGN{" "}
                  <span className="signup__subtitle-text-highlight">UP </span>
                </h2>
              </div>
              <div className="signup__bracket-right">{"}"}</div>
            </div>
            <div className="signup__form-wrapper">
              <form className="signup__form">
                <div className="signup__input-wrapper">
                  <button
                    type="button"
                    name="userType"
                    value="jobSeeker"
                    className={`signup__input ${userType === "jobSeeker" ? "active" : ""}`}
                    onClick={() => handleUserTypeChange("jobSeeker")}
                  >
                    <span className="signup__input-text">JOBSEEKER</span>
                  </button>

                  <button
                    type="button"
                    name="userType"
                    value="recruiter"
                    className={`signup__input ${userType === "recruiter" ? "active" : ""}`}
                    onClick={() => handleUserTypeChange("recruiter")}
                  >
                    <span className="signup__input-text">RECRUITER</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="signup__button-wrapper">
            {showError && (
              <div className="signup__error-message">
                PLEASE SELECT EITHER JOB SEEKER OR RECRUITER
              </div>
            )}
            <div className="signup__cta-buttons">
              <Link to={userType === "jobSeeker" ? "/jobSeeker" : "/recruiter"}>
                <button
                  className="signup__button"
                  onClick={handleContinueClick}
                >
                  CONTINUE
                </button>
              </Link>
              <h2 className="signup__subtitle-text-highlight signup__special-line">
                Or, if you've already signed up
              </h2>

              <Link to="/signin">
                <button className="signup__button signup__sign-in-button">
                  SIGN IN
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signup;
