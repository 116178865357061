import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import "./UpdateRecruiter.scss";
import awsConfig from "../../awsConfig/awsConfig.json";

function UpdateRecruiterProfile() {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    company: "",
    sector: "",
    address: "",
    locationCity: "",
    phone: "",
    email: "",
  });
  const glitterVideo = `${awsConfig.s3BucketUrl}${awsConfig.videoPath}`;
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodedToken = jwtDecode(token);
        const userIdFromToken = decodedToken.userId;

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/recruiter/recruiter/${userIdFromToken}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const userData = response.data;
        setFormData(userData);
      } catch (error) {
        console.error("Error fetching recruiter data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem("token");
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.userId;

      const updates = {
        userId: userId,
        ...formData,
      };

      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/recruiter/updateRecruiter`,
        updates,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSuccessMessage("Profile updated successfully!");
      } else {
        setSuccessMessage("Failed to update profile.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      setSuccessMessage("");
    } finally {
      navigate("/recruitersearchchoices");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <section className="recruiter-update-profile">
      <div className="recruiter-update-profile__title-box">
        <div className="recruiter-update-profile__background-video">
          <video
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            style={{ opacity: 0, transition: "opacity 0.5s ease" }}
            onLoadedData={(event) => {
              event.target.style.opacity = 1;
            }}
          >
            <source src={glitterVideo} type="video/mp4" />
          </video>
          <h1 className="recruiter-update-profile__title">
            UPDATE RECRUITER PROFILE
          </h1>
          <div className="recruiter-update-profile__content-wrapper">
            <div className="recruiter-update-profile__content">
              <div className="recruiter-update-profile__brackets">
                <div className="recruiter-update-profile__bracket-left">
                  {"{"}
                </div>
                <div className="recruiter-update-profile__text-wrapper">
                  <h2 className="recruiter-update-profile__subtitle">
                    CHANGE ANY FIELD
                  </h2>
                </div>
                <div className="recruiter-update-profile__bracket-right">
                  {"}"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="recruiter-update-profile__container">
          <div className="recruiter-update-profile__formwrapper">
            <form
              className="recruiter-update-profile__form"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="recruiter-update-profile__columns-box">
                <div className="recruiter-update-profile__left-column">
                  <div className="recruiter-update-profile__form-field">
                    <label
                      className="recruiter-update-profile__label"
                      htmlFor="firstName"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder="First Name"
                      className="recruiter-update-profile__input"
                      autoComplete="given-name"
                    />
                  </div>

                  <div className="recruiter-update-profile__form-field">
                    <label
                      className="recruiter-update-profile__label"
                      htmlFor="lastName"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      placeholder="Last Name"
                      className="recruiter-update-profile__input"
                      autoComplete="family-name"
                    />
                  </div>

                  <div className="recruiter-update-profile__form-field">
                    <label
                      className="recruiter-update-profile__label"
                      htmlFor="jobTitle"
                    >
                      Job Title
                    </label>
                    <input
                      type="text"
                      id="jobTitle"
                      name="jobTitle"
                      value={formData.jobTitle}
                      onChange={handleChange}
                      placeholder="Job Title"
                      className="recruiter-update-profile__input"
                    />
                  </div>

                  <div className="recruiter-update-profile__form-field">
                    <label
                      className="recruiter-update-profile__label"
                      htmlFor="company"
                    >
                      Company
                    </label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                      placeholder="Company"
                      className="recruiter-update-profile__input"
                    />
                  </div>

                  <div className="recruiter-update-profile__form-field">
                    <label
                      className="recruiter-update-profile__label"
                      htmlFor="sector"
                    >
                      Sector
                    </label>
                    <input
                      type="text"
                      id="sector"
                      name="sector"
                      value={formData.sector}
                      onChange={handleChange}
                      placeholder="sector"
                      className="recruiter-update-profile__input"
                    />
                  </div>

                  <div className="recruiter-update-profile__form-field">
                    <label
                      className="recruiter-update-profile__label"
                      htmlFor="address"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      placeholder="Address"
                      className="recruiter-update-profile__input"
                      autoComplete="street-address"
                    />
                  </div>

                  <div className="recruiter-update-profile__form-field">
                    <label
                      className="recruiter-update-profile__label"
                      htmlFor="locationCity"
                    >
                      Location City
                    </label>
                    <input
                      type="text"
                      id="locationCity"
                      name="locationCity"
                      value={formData.locationCity}
                      onChange={handleChange}
                      placeholder="Location City"
                      className="recruiter-update-profile__input"
                    />
                  </div>

                  <div className="recruiter-update-profile__form-field">
                    <label
                      className="recruiter-update-profile__label"
                      htmlFor="phone"
                    >
                      Phone
                    </label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Phone"
                      className="recruiter-update-profile__input"
                      autoComplete="tel"
                    />
                  </div>

                  <div className="recruiter-update-profile__form-field">
                    <label
                      className="recruiter-update-profile__label"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email"
                      className="recruiter-update-profile__input"
                      autoComplete="username"
                    />
                  </div>
                </div>
              </div>

              {successMessage && (
                <div className="recruiter-update-profile__success-message">
                  {successMessage}
                </div>
              )}
              <div className="recruiter-update-profile__button-wrapper">
                <div className="recruiter-update-profile__columns-box-two">
                  <button
                    type="submit"
                    className="recruiter-update-profile__button"
                  >
                    Update Profile
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
export default UpdateRecruiterProfile;
