import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./styles/resets.scss";
import "./styles/globalStyles.scss";

import Home from "./pages/Home/HomePage";
import SearchChoices from "./pages/SearchChoices/SearchChoicesPage";
import RecruiterSearchChoices from "./pages/SearchChoices/RecruiterSearchChoicesPage";
import ExtJobSearchPage from "./pages/ExtJobSearch/ExtJobSearchPage";
import TalentSearchPage from "./pages/TalentSearch/TalentSearchPage";
import AboutPage from "./pages/About/AboutPage";
import SignupPage from "./pages/Signup/SignupPage";
import SigninPage from "./pages/Signin/SigninPage";
import ContactPage from "./pages/Contact/ContactPage";
import JobSeekerComponent from "./components/JobSeeker/JobSeeker";
import RecruiterComponent from "./components/Recruiter/Recruiter";
import JobPostForm from "./components/JobPost/JobPost";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import UpdateRecruiterProfile from "./components/UpdateRecruiter/UpdateRecruiter";
import UpdateJobSeekerProfile from "./components/UpdateJobSeeker/UpdateJobSeeker";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/searchchoices" element={<SearchChoices />} />
          <Route
            path="/recruitersearchchoices"
            element={<RecruiterSearchChoices />}
          />
          <Route path="/extsearch" element={<ExtJobSearchPage />} />
          <Route path="/talentsearch" element={<TalentSearchPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/signin" element={<SigninPage />} />
          <Route path="/jobseeker" element={<JobSeekerComponent />} />
          <Route path="/recruiter" element={<RecruiterComponent />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/postjob" element={<JobPostForm />} />
          <Route path="/updateRecruiter" element={<UpdateRecruiterProfile />} />
          <Route path="/updateJobSeeker" element={<UpdateJobSeekerProfile />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
