import React, { useEffect } from "react";
import "./Modal.scss";

const Modal = ({ isOpen, closeModal, selectedJob }) => {
  useEffect(() => {
    const modalScrollContainer = document.querySelector(".modal__body");
    if (modalScrollContainer) {
      modalScrollContainer.scrollTo({
        top: 0,
        behavior: "auto",
      });
    }
  }, [selectedJob]);
  if (!isOpen || !selectedJob) return null;

  return (
    <section className="modal__scroll-container">
      <div className="modal__overlay" onClick={closeModal}>
        <div
          className="modal__content"
          onClick={(event) => event.stopPropagation()}
        >
          <div className="modal__body">
            <div className="modal__job-logo-wrapper">
              {selectedJob.employer_logo ? (
                <img
                  src={selectedJob.employer_logo}
                  alt={`${selectedJob.employer_name} logo`}
                  className="modal__job-logo"
                />
              ) : (
                <div className="modal__job-no-logo"></div>
              )}
            </div>
            <div className="modal__text-wrapper">
              <h3 className="modal__text modal__job-title">
                {selectedJob.job_title}
              </h3>

              <p className="modal__text modal__job-company">
                {selectedJob.employer_name}
              </p>

              <p className="modal__text modal__job-city">
                {selectedJob.job_city}
              </p>

              {Object.entries(selectedJob).map(([key, value]) => {
                if (
                  key === "job_description" ||
                  key === "job_posted_at_timestamp" ||
                  key === "job_offer_expiration_timestamp" ||
                  (key.includes("salary") && value !== null)
                ) {
                  return (
                    <p className="modal__text" key={key}>
                      {key === "job_posted_at_timestamp"
                        ? `DATE POSTED: ${new Date(value * 1000).toLocaleDateString("en-GB")}`
                        : key === "job_offer_expiration_timestamp"
                          ? `APPLY BY: ${new Date(value * 1000).toLocaleDateString("en-GB")}`
                          : key.includes("salary")
                            ? key === "job_min_salary"
                              ? `MIN SALARY: £${value.toLocaleString()}`
                              : key === "job_max_salary"
                                ? `MAX SALARY: £${value.toLocaleString()}`
                                : null
                            : `${key.replace(/_/g, " ").toUpperCase()}: ${value}`}
                    </p>
                  );
                }
                return null;
              })}
            </div>
          </div>

          <div className="modal__action-wrapper">
            <a
              className="modal__apply-button"
              href={selectedJob.job_apply_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              APPLY
            </a>
          </div>

          <div className="modal__close-button-wrapper">
            <button className="modal__close-button" onClick={closeModal}>
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Modal;
