import React from "react";
import {Link} from "react-router-dom";


import "./Header.scss";

function Header() {
    return (
      <header className="navbar">
        <div className="navbar__wrapper">
            <section className="navbar__container">
              <Link to="/" className="navbar__link">
                <h3 className="navbar__text">HOME</h3>
              </Link>
              <Link to="/about" className="navbar__link">
                <h3 className="navbar__text">ABOUT</h3>
              </Link>
              <Link to="/extsearch" className="navbar__link--special1">
                <h3 className="navbar__text navbar__text--special1">JOBS</h3>
              </Link>
              <Link to="/talentsearch" className="navbar__link--special1">
                <h3 className="navbar__text navbar__text--special1">TALENT</h3>
              </Link>
              <Link to="/signin" className="navbar__link">
                <h3 className="navbar__text">SIGN IN</h3>
              </Link>
              <Link to="/signup" className="navbar__link">
                <h3 className="navbar__text">SIGN UP</h3>
              </Link>
            </section>

            <section className="navbar__mobile-links">
              <Link to="/extsearch" className="navbar__link--special2">
                <h3 className="navbar__text--special2">JOBS</h3>
              </Link>
              <Link to="/talentsearch" className="navbar__link--special2">
                <h3 className="navbar__text--special2">TALENT</h3>
              </Link>
          </section>
        </div>
        
      </header>
    );
  }
  
export default Header;