import React from "react";
import { Link, useNavigate } from "react-router-dom";

import "./Footer.scss";

function Footer() {
  const navigate = useNavigate();

  const handleSignOut = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <footer className="footer">
      <section className="footer__container">
        <div className="footer__left">
          <Link to="/contact" className="footer__left-link">
            <h3 className="footer__left-text">CONTACT</h3>
          </Link>
        </div>

        <div
          className="footer__center"
          onClick={handleSignOut}
          style={{ cursor: "pointer" }}
        >
          <h3 className="footer__center-text">SIGN OUT</h3>
        </div>

        <div className="footer__right">
          <Link to="/" className="footer__right-link">
            <h3 className="footer__right-text">CODERATI</h3>
          </Link>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
