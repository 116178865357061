import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./JobSeeker.scss";
import awsConfig from "../../awsConfig/awsConfig.json";

function JobSeekerSignUp() {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [fileData, setFileData] = useState({ image: null, resume: null });
  const [errorMessage, setErrorMessage] = useState("");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    keySkills: "",
    image: "",
    resume: "",
    address: "",
    locationCity: "",
    phone: "",
    email: "",
    experience: "",
    previousRoles: "",
    achievements: "",
    portfolio: "",
    gitHub: "",
    setPassword: "",
    checkPassword: "",
  });
  const glitterVideo = `${awsConfig.s3BucketUrl}${awsConfig.videoPath}`;

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });

    if (
      event.target.name === "setPassword" ||
      event.target.name === "checkPassword"
    ) {
      setPasswordError(false);
    }

    if (
      event.target.name === "checkPassword" &&
      formData.setPassword !== event.target.value
    ) {
      setPasswordError(true);
    }
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFileData({ ...fileData, [name]: files[0] });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.setPassword !== formData.checkPassword) {
      setPasswordError(true);
      return;
    }

    const submissionData = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key !== "setPassword" && key !== "checkPassword") {
        submissionData.append(key, formData[key]);
      }
    });
    submissionData.append("password", formData.setPassword);

    if (fileData.image) submissionData.append("image", fileData.image);
    if (fileData.resume) submissionData.append("resume", fileData.resume);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/jobSeekerSignUp`,
        submissionData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        setSuccessMessage("Sign up successful!");
        setTimeout(() => navigate("/signin"), 1500);
      } else {
        console.error(
          "Signup was not successful. Status code:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error submitting signup form", error);

      const errorMessage =
        error.response && error.response.status === 409
          ? "Email already exists. Please use a different email."
          : "An error occurred during sign up. Please try again.";
      setErrorMessage(errorMessage);
    }
  };

  return (
    <section className="jobseeker-signup">
      <div className="jobseeker-signup__title-box">
        <div className="jobseeker-signup__background-video">
          <video
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            style={{ backgroundColor: "$opaque-background-color" }}
            onLoadedData={(event) =>
              (event.target.style.backgroundColor = "transparent")
            }
          >
            <source src={glitterVideo} type="video/mp4" />
          </video>

          <h1 className="jobseeker-signup__title">JOBSEEKER</h1>
          <div className="jobseeker-signup__content-wrapper">
            <div className="jobseeker-signup__content">
              <div className="jobseeker-signup__brackets">
                <div className="jobseeker-signup__bracket-left">{"{"}</div>
                <div className="jobseeker-signup__text-wrapper">
                  <h2 className="jobseeker-signup__subtitle">SIGN UP</h2>
                </div>
                <div className="jobseeker-signup__bracket-right">{"}"}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="jobseeker-signup__container">
        <div className="jobseeker-signup__formwrapper">
          <form
            className="jobseeker-signup__form"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="jobseeker-signup__columns-box">
              <div className="jobseeker-signup__left-column">
                <div className="jobseeker-signup__form-field">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                    className="jobseeker-signup__input"
                    required
                  />
                </div>
                <div className="jobseeker-signup__form-field">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                    className="jobseeker-signup__input"
                    required
                  />
                </div>
                <div className="jobseeker-signup__form-field">
                  <label htmlFor="jobTitle">Job Title</label>
                  <input
                    type="text"
                    id="jobTitle"
                    name="jobTitle"
                    value={formData.jobTitle}
                    onChange={handleChange}
                    placeholder="Job Title"
                    className="jobseeker-signup__input"
                    required
                  />
                </div>
                <div className="jobseeker-signup__form-field">
                  <label htmlFor="keySkills">Key Skills</label>
                  <input
                    type="text"
                    id="keySkills"
                    name="keySkills"
                    value={formData.keySkills}
                    onChange={handleChange}
                    placeholder="Key Skills"
                    className="jobseeker-signup__input"
                    required
                  />
                </div>
                <div className="jobseeker-signup__form-field">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    placeholder="Address"
                    className="jobseeker-signup__input"
                    required
                    autoComplete="street-address"
                  />
                </div>
                <div className="jobseeker-signup__form-field">
                  <label htmlFor="locationCity">Location</label>
                  <input
                    type="text"
                    id="locationCity"
                    name="locationCity"
                    value={formData.locationCity}
                    onChange={handleChange}
                    placeholder="City"
                    className="jobseeker-signup__input"
                    required
                  />
                </div>
                <div className="jobseeker-signup__form-field">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone"
                    className="jobseeker-signup__input"
                    required
                    autoComplete="tel"
                  />
                </div>
                <div className="jobseeker-signup__form-field">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    className="jobseeker-signup__input"
                    required
                    autoComplete="username"
                  />
                </div>
                <div className="jobseeker-signup__form-field">
                  <label htmlFor="experience">Experience</label>
                  <textarea
                    type="text"
                    id="experience"
                    name="experience"
                    value={formData.experience}
                    onChange={handleChange}
                    placeholder="Include number of years in each role"
                    className="jobseeker-signup__input"
                    required
                  />
                </div>
              </div>

              <div className="jobseeker-signup__right-column">
                <div className="jobseeker-signup__form-field">
                  <label htmlFor="previousRoles">Previous Roles</label>
                  <input
                    type="text"
                    id="previousRoles"
                    name="previousRoles"
                    value={formData.previousRoles}
                    onChange={handleChange}
                    placeholder="Previous Roles"
                    className="jobseeker-signup__input"
                    required
                  />
                </div>
                <div className="jobseeker-signup__form-field jobseeker-signup__text-area">
                  <label htmlFor="achievements">Achievements</label>
                  <textarea
                    type="text"
                    id="achievements"
                    name="achievements"
                    value={formData.achievements}
                    onChange={handleChange}
                    placeholder="Achievements"
                    className="jobseeker-signup__input"
                    required
                  />
                </div>

                <div className="jobseeker-signup__form-field">
                  <label htmlFor="portfolio">Portfolio</label>
                  <input
                    type="text"
                    id="portfolio"
                    name="portfolio"
                    value={formData.setPortfolio}
                    onChange={handleChange}
                    placeholder="Portfolio Link"
                    className="jobseeker-signup__input"
                  />
                </div>

                <div className="jobseeker-signup__form-field">
                  <label htmlFor="gitHub">Git Hub profile</label>
                  <input
                    type="text"
                    id="gitHub"
                    name="gitHub"
                    value={formData.setgitHub}
                    onChange={handleChange}
                    placeholder="Git Hub Link"
                    className="jobseeker-signup__input"
                  />
                </div>

                <div className="jobseeker-signup__form-field">
                  <label htmlFor="image" className="jobseeker-signup__label">
                    Upload Photo (optional)
                  </label>
                  <input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="jobseeker-signup__input"
                  />
                </div>
                <div className="jobseeker-signup__form-field">
                  <label htmlFor="resume" className="jobseeker-signup__label">
                    Upload CV / Resume (optional)
                  </label>
                  <input
                    type="file"
                    id="resume"
                    name="resume"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                    className="jobseeker-signup__input"
                  />
                </div>

                <div className="jobseeker-signup__form-field">
                  <label htmlFor="setPassword">Set Password</label>
                  <input
                    type="password"
                    id="setPassword"
                    name="setPassword"
                    value={formData.setPassword}
                    onChange={handleChange}
                    placeholder="Set Password"
                    className="jobseeker-signup__input"
                    required
                    autoComplete="new-password"
                  />
                </div>
                <div className="jobseeker-signup__form-field jobseeker-signup__form-field-password check">
                  <label htmlFor="checkPassword">Check Password</label>
                  <input
                    type="password"
                    id="checkPassword"
                    name="checkPassword"
                    value={formData.checkPassword}
                    onChange={handleChange}
                    placeholder="Check Password"
                    className="jobseeker-signup__input"
                    required
                    autoComplete="new-password"
                  />
                  {passwordError && (
                    <div className="jobseeker-signup__error-message">
                      Passwords do not match. Please re-confirm your password.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="jobseeker-signup__columns-box-two">
              <div className="jobseeker-signup__button-wrapper">
                <button type="submit" className="jobseeker-signup__button">
                  Sign Up
                </button>
              </div>
              {errorMessage && (
                <div className="jobseeker-signup__error-message">
                  {errorMessage}
                </div>
              )}
              {successMessage && (
                <div className="jobseeker-signup__success-message">
                  {successMessage}
                </div>
              )}

              <div className="jobseeker-signup__login-link-wrapper">
                <p className="jobseeker-signup__login-link-terms">
                  TERMS: By signing up to CODERATI, you understand and agree
                  that your contact details will be available to signed up
                  recruiters and that CODERATI is not responsible for any
                  consequences{" "}
                </p>
                <p className="jobseeker-signup__login-link">
                  Already have an account?{" "}
                  <Link
                    to="/signin"
                    className="jobseeker-signup__login-link-special"
                  >
                    Sign In
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default JobSeekerSignUp;
