import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./JobPost.scss";
import awsConfig from "../../awsConfig/awsConfig.json";

const JobPostForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    jobTitle: "",
    company: "",
    location: "",
    jobDescription: "",
    skillsRequired: "",
    experience: "",
    applyBy: "",
    maxSalary: "",
    minSalary: "",
    postedBy: "",
    apply: "",
    phone: "",
    email: "",
  });
  const glitterVideo = `${awsConfig.s3BucketUrl}${awsConfig.videoPath}`;

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/postedjobs`, formData);

      navigate("/RecruiterSearchChoices");
    } catch (error) {
      console.error("Error submitting job post form", error);
    }
  };

  return (
    <section className="job-post">
      <div className="job-post__container">
        <div className="job-post__background-video">
          <video
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            style={{ opacity: 0, transition: "opacity 0.5s ease" }}
            onLoadedData={(event) => {
              event.target.style.opacity = 1;
            }}
          >
            <source src={glitterVideo} type="video/mp4" />
          </video>
          <h1 className="job-post__title">POST A JOB</h1>
          <div className="job-post__content-wrapper">
            <div className="job-post__content">
              <div className="job-post__brackets">
                <div className="job-post__bracket-left">{"<"}</div>
                <div className="job-post__text-wrapper">
                  <h2 className="job-post__subtitle">DETAILS</h2>
                </div>
                <div className="job-post__bracket-right">{">"}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="job-post__formcontainer">
          <div className="job-post__formwrapper">
            <form className="job-post__form" onSubmit={handleSubmit}>
              <div className="job-post__field">
                <label htmlFor="jobTitle">Job Title</label>
                <input
                  type="text"
                  id="jobTitle"
                  name="jobTitle"
                  value={formData.jobTitle}
                  onChange={handleChange}
                  className="job-post__input"
                  required
                  autoComplete="off"
                />
              </div>

              <div className="job-post__field">
                <label htmlFor="company">Company</label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  className="job-post__input"
                  required
                  autoComplete="organization"
                />
              </div>

              <div className="job-post__field">
                <label htmlFor="location">Location</label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  className="job-post__input"
                  required
                  autoComplete="off"
                />
              </div>

              <div className="job-post__field job-post__text-area">
                <label htmlFor="jobDescription">Job Description</label>
                <textarea
                  className="job-post__text-area"
                  id="jobDescription"
                  name="jobDescription"
                  value={formData.jobDescription}
                  onChange={handleChange}
                  required
                  autoComplete="off"
                />
              </div>

              <div className="job-post__field">
                <label htmlFor="skillsRequired">Skills Required</label>
                <input
                  type="text"
                  id="skillsRequired"
                  name="skillsRequired"
                  value={formData.skillsRequired}
                  onChange={handleChange}
                  placeholder="Eg: Javascript, Python, MySQL, REACT, etc"
                  className="job-post__input"
                  required
                  autoComplete="off"
                />
              </div>

              <div className="job-post__field">
                <label htmlFor="experience">Experience</label>
                <input
                  type="text"
                  id="experience"
                  name="experience"
                  value={formData.experience}
                  onChange={handleChange}
                  placeholder="Enter experience required, eg no of years in role or 'graduate'"
                  className="job-post__input"
                  required
                  autoComplete="off"
                />
              </div>

              <div className="job-post__field">
                <label htmlFor="applyBy">Apply by</label>
                <input
                  type="text"
                  id="applyBy"
                  name="applyBy"
                  value={formData.applyBy}
                  onChange={handleChange}
                  placeholder="Application Deadline"
                  className="job-post__input"
                  required
                  autoComplete="off"
                />
              </div>

              <div className="job-post__field">
                <label htmlFor="maxSalary">Max Salary</label>
                <input
                  type="text"
                  id="maxSalary"
                  name="maxSalary"
                  value={formData.maxSalary}
                  onChange={handleChange}
                  placeholder="Maximum Salary"
                  className="job-post__input"
                  required
                  autoComplete="off"
                />
              </div>

              <div className="job-post__field">
                <label htmlFor="minSalary">Min Salary</label>
                <input
                  type="text"
                  id="minSalary"
                  name="minSalary"
                  value={formData.minSalary}
                  onChange={handleChange}
                  placeholder="Minimum Salary"
                  className="job-post__input"
                  required
                  autoComplete="off"
                />
              </div>

              <div className="job-post__field">
                <label htmlFor="postedBy">Posted by</label>
                <input
                  type="text"
                  id="postedBy"
                  name="postedBy"
                  value={formData.postedBy}
                  onChange={handleChange}
                  placeholder="Your name"
                  className="job-post__input"
                  required
                  autoComplete="off"
                />
              </div>

              <div className="job-post__field">
                <label htmlFor="apply">Apply to</label>
                <input
                  type="text"
                  id="apply"
                  name="apply"
                  value={formData.apply}
                  onChange={handleChange}
                  placeholder="Enter link or email for applications"
                  className="job-post__input"
                  required
                  autoComplete="off"
                />
              </div>

              <div className="job-post__field">
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Enter a phone contact"
                  className="job-post__input"
                  required
                  autoComplete="tel"
                />
              </div>

              <div className="job-post__field">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter an email contact"
                  className="job-post__input"
                  required
                  autoComplete="email"
                />
              </div>

              <div className="job-post__button-wrapper">
                <button type="submit" className="job-post__submit-button">
                  Post Job
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JobPostForm;
