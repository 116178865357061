import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./HomePage.scss";
import Modal from "../../components/Modal/Modal";
import awsConfig from "../../awsConfig/awsConfig.json";

const imageFolder = `${process.env.REACT_APP_API_URL}/images/`;
const generateImageFilenames = (length) => {
  return Array.from({ length }, (_, index) => {
    const gender = index % 2 === 0 ? "female" : "male";
    const number = String(Math.floor(index / 2) + 1).padStart(3, "0");
    return `${gender}${number}.jpg`;
  });
};

const Home = () => {
  const frameRef = useRef(0);
  const images = useMemo(() => generateImageFilenames(200), []);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [params, setParams] = useState({ sampleTitle: "", sampleLocation: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const fetchInitiated = useRef(false);
  const glitterVideo = `${awsConfig.s3BucketUrl}${awsConfig.videoPath}`;

  useEffect(() => {
    const intervalId = setInterval(() => {
      frameRef.current = (frameRef.current + 1) % images.length;
      const newBackgroundImage = `url(${imageFolder}${images[frameRef.current]})`;
      document.querySelectorAll(".animation").forEach((box) => {
        box.style.backgroundImage = newBackgroundImage;
      });
    }, 500);

    return () => clearInterval(intervalId);
  }, [images]);

  useEffect(() => {
    const fetchData = async () => {
      if (fetchInitiated.current) {
        return;
      }

      setLoading(true);
      fetchInitiated.current = true;
      try {
        const paramsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/params`
        );

        const { sampleTitle, sampleLocation } = paramsResponse.data;

        setParams({ sampleTitle, sampleLocation });

        const jobsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/sampleextsearch`,
          {
            params: { sampleTitle, sampleLocation },
          }
        );

        const jobsArray = jobsResponse.data.jobs.data;
        const sortedJobs = jobsArray
          .sort((a, b) => b.job_posted_at_timestamp - a.job_posted_at_timestamp)
          .slice(0, 4);

        setJobs(sortedJobs);
        setIsLoaded(true);
      } catch (error) {
        console.error("Failed to fetch data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const openModal = (job) => {
    if (selectedJob && selectedJob.job_id === job.job_id) {
      setIsModalOpen(!isModalOpen);
    } else {
      setSelectedJob(job);
      setIsModalOpen(true);
      window.scrollTo(0, 0);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section className="home">
      <div className="home__background-video">
        <video
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          style={{ opacity: 0, transition: "opacity 0.5s ease" }}
          onLoadedData={(event) => {
            event.target.style.opacity = 1;
          }}
        >
          <source src={glitterVideo} type="video/mp4" />
        </video>
      </div>
      <div className="home__box">
        <h1 className="home__title">CODERATI</h1>
        <div className="home__content-wrapper">
          <div className="home__animation-box">
            <div className="animation animation-left"></div>

            <div className="home__content">
              <div className="home__brackets">
                <div className="home__bracket-left">{"{"}</div>
                <div className="home__mission-statement">
                  <h2 className="home__mission-statement-text">
                    Helping
                    <span className="home__mission-statement-text home__tech-companies">
                      {" "}
                      tech COMPANIES{" "}
                    </span>
                    find
                  </h2>
                  <h2 className="home__mission-statement-text">
                    highly skilled coders
                  </h2>
                </div>
                <div className="home__bracket-right">{"}"}</div>
              </div>
            </div>
            <div className="animation animation-right"></div>
          </div>
          <div className="animation animation-bottom"></div>
        </div>
        <div className="home__button-wrapper">
          <Link to="/signup">
            <button className="home__get-started-button">
              GET <span className="home__highlighted-text">STARTED</span>
            </button>
          </Link>
        </div>
      </div>

      <div className="home__featured-jobs">
        <h2 className="home__featured-jobs-title">FEATURED JOBS</h2>
        <div className="home__featured-jobs-subtitle-wrapper">
          <h3
            className={`home__featured-jobs-subtitle ${isLoaded ? "visible" : ""}`}
          >
            {params && params.sampleTitle && params.sampleLocation
              ? `Latest posts for ${params.sampleTitle}s in your area: ${params.sampleLocation}`
              : ""}
          </h3>
        </div>

        <div className="home__featured-jobs-loading-wrapper">
          {loading ? (
            <p className="home__featured-jobs-loading">
              Loading featured jobs...
            </p>
          ) : (
            <p className="home__featured-jobs-loading invisible">
              Loading featured jobs...
            </p>
          )}
        </div>

        <div
          className={`home__featured-jobs-list ${isLoaded ? "visible" : ""}`}
        >
          {jobs.map((job) => (
            <div
              key={job.job_id}
              className="home__featured-jobs-job"
              onClick={() => openModal(job)}
            >
              <div className="home__featured-jobs-logowrapper">
                {job.employer_logo ? (
                  <img
                    src={job.employer_logo}
                    alt={`${job.employer_name} logo`}
                    className="home__featured-jobs-logo"
                    onError={(event) => {
                      event.target.onerror = null;
                      event.target.style.display = "none";
                      const fallbackDiv = document.createElement("div");
                      fallbackDiv.className = "home__featured-jobs-no-logo";
                      event.target.parentNode.appendChild(fallbackDiv);
                    }}
                  />
                ) : (
                  <div className="home__featured-jobs-no-logo"></div>
                )}
              </div>
              <h3 className="home__featured-jobs-job-title">{job.job_title}</h3>
              <p className="home__featured-jobs-company">{job.employer_name}</p>
              <p className="home__featured-jobs-city">{job.job_city}</p>
              <p className="home__featured-jobs-date">
                Posted on:{" "}
                {new Date(
                  job.job_posted_at_timestamp * 1000
                ).toLocaleDateString()}
              </p>
            </div>
          ))}
        </div>

        <Modal
          isOpen={isModalOpen}
          closeModal={closeModal}
          selectedJob={selectedJob}
          handleReturnToList={closeModal}
        />
        <div className="home__bottom-text">
          <span className="home__featured-jobs-link">
            To search full listings for any role,{" "}
          </span>
          <Link to="/signup" className="home__featured-jobs-link">
            Sign Up
          </Link>
          <span className="home__featured-jobs-link"> or </span>
          <Link to="/signin" className="home__featured-jobs-link">
            Sign In
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Home;
